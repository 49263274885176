l<template>
   <div class="position-relative">
      <section class="blockElement">
         <div class="container privacyPolicy">  
            <h1>{{contentlist.aml_policy_global_content1}}</h1>
            
            <p>{{contentlist.aml_policy_global_content3}}</p>
            <h5 class="mb-md-3">{{contentlist.aml_policy_global_content4}}</h5>
            <p><strong>{{contentlist.aml_policy_global_content5}}</strong></p>
            <ul>
               <li>{{contentlist.aml_policy_global_content5_list1}}</li>
               <li>{{contentlist.aml_policy_global_content5_list2}}</li>
               <li>{{contentlist.aml_policy_global_content5_list3}}</li>
               <li>{{contentlist.aml_policy_global_content5_list4}}</li>
               <li>{{contentlist.aml_policy_global_content5_list5}}</li>
            </ul>
            
         </div>
      </section>
   </div>
</template>
<script>
 import {
    commonAllmixins
  } from '@/plugins/commonAll' //anamica
  export default ({
    mixins: [commonAllmixins], //anamica
    data() {
      return {
        tab: 1,
      };
    },
   
    created() {
      // console.log(this.contentlist)
    }
  });
</script>