<template>
   <div id="main-wrapper">
      <!-- <Container/> --> 
      <apec-aml-policy v-if="entityName == 'apec'"></apec-aml-policy>
      <global-aml-policy v-else></global-aml-policy>
      <!-- <Footer /> -->
      <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 text-center primarybgSection">
                  <div class="criptoIcon">
                     <span class="left-tp one"><img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid"></span>
                     <span class="left-tp tow"><img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid"></span>
                     <span class="left-tp three"><img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid"></span>
                     <span class="left-tp four"><img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid"></span>
                  </div>
                  <div class="content position-relative">
                     <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
                     <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
                     <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
   import globalAmlPolicy from "@/components/global/aml-policy";
  import apecAmlPolicy from "@/components/apec/aml-policy";
  import { commonAllmixins } from '@/plugins/commonAll'   //anamica
   export default ({
      mixins: [ commonAllmixins],   //anamica
      components: { globalAmlPolicy, apecAmlPolicy },
      data() {
         return {
            tab: 1,
            
         };
      },
      
      created(){
         console.log(this.contentlist)
      }
      
      
   });
   
</script>